<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      :size="'50%'"
      :before-close="handleClose"
    >
      <el-card>
        <el-table
          :data="list"
          style="width: 100%;"
          border
          stripe
          v-if="interface == 1"
          ref="multipleTable"
          :row-key="
            row => {
              return row.uid
            }
          "
          @selection-change="selChange"
          :header-cell-style="
            () => {
              return 'background:#F5F7FA;'
            }
          "
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column prop="uid" width="100" label="编号">
          </el-table-column>
          <el-table-column prop="realName" label="真实姓名"> </el-table-column>
          <el-table-column prop="username" label="用户名" width="180">
          </el-table-column>
          <el-table-column prop="phone" label="电话" width="180">
          </el-table-column>
        </el-table>
        <el-table
          :data="list"
          style="width: 100%;"
          border
          ref="multipleTable"
          stripe
          v-if="interface == 2"
          :row-key="
            row => {
              return row.id
            }
          "
          @selection-change="selChange"
          :header-cell-style="
            () => {
              return 'background:#F5F7FA;'
            }
          "
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column prop="id" width="100" label="编号">
          </el-table-column>
          <el-table-column prop="name" label="真实姓名"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="180">
          </el-table-column>
          <el-table-column prop="phone" label="电话" width="180">
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page.sync="currentPage"
          :total="total"
          :page-size="pagesize"
          @current-change="handleCurrentChange"
          v-if="interface == 1 || interface == 2"
        >
        </el-pagination>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="ruleList"
          label-width="100px"
          class="demo-ruleForm"
          v-if="interface == 3"
          size="small"
        >
          <el-form-item label="票种名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="套票设置">
            <el-select v-model="ruleForm.type" placeholder="请选择套票">
              <el-option label="免费票" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格（元）" prop="price">
            <el-input-number
              v-model="ruleForm.price"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="人均票价" prop="balancePrice">
            <!-- <el-input v-model="ruleForm.balancePrice"></el-input> -->
            <el-input-number
              v-model="ruleForm.balancePrice"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="数量" prop="number">
            <!-- <el-input v-model="ruleForm.number"></el-input> -->
            <el-input-number
              v-model="ruleForm.number"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="审核设置" prop="needCheck">
            <el-select v-model="ruleForm.needCheck" placeholder="请选择套票">
              <el-option label="需要审核" :value="0"></el-option>
              <el-option label="无需审核" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述" prop="desc">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.desc"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <el-form v-if="interface == 4">
          <!-- {{formList}} -->
          <el-form-item
            v-for="(item, i) in ruleForm"
            :key="i"
            :label="item.name"
          >
          <span>{{item.value}}</span>
            <!-- {{ruleForm[item.model]}} -->
          </el-form-item>
        </el-form>
        <div style="text-align:right;" v-if="!(interface == 4)">
          <el-button
            type="primary"
            @click="editUser"
            v-if="editId || editId == 0"
            >{{ btn ? '修 改' : 'loading..' }}</el-button
          >
          <el-button type="primary" @click="addUser" v-else>{{
            btn ? '添 加' : 'loading..'
          }}</el-button>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, Object],
      default: '提示'
    }
  },
  watch: {
    // drawer(newValue, oldValue) {
    //   console.log(this.ruleForm)
    //   console.log(this.formList)
    // }
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      list: [],
      total: 0,
      currentPage: 1,
      pagesize: 10,
      btn: true,
      selectTheUser: [],
      interface: 1,
      activityId: '',
      ruleForm: {
        name: '',
        type: 0,
        price: '',
        balancePrice: '',
        number: 0,
        needCheck: 0,
        desc: ''
      },
      formList: [],
      ruleList: {
        name: [{ required: true, message: '请输入票种名称', trigger: 'blur' }]
      },
      editId: null
    }
  },
  methods: {
    async editUser() {
      if (this.ruleForm.id) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/ticketEdit',
          this.ruleForm
        )
        this.$emit('getTicket')
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.drawer = false
        } else {
          this.$message.error(res.message)
        }

        return
      }
      this.drawer = false
      this.$emit('edit', this.ruleForm, this.editId)
    },
    async addUser() {
      if (this.interface == 1) {
        var { data: res } = await this.$http.post(
          '/admin/Activity/managerAdd',
          {
            amId: this.selectTheUser.toString()
          }
        )
      } else if (this.interface == 2) {
        console.log(this.selectTheUser)
        var { data: res } = await this.$http.post(
          '/admin/Activity/activityManagersAdd',
          {
            managerIds: this.selectTheUser.toString(),
            activityId: this.activityId
          }
        )
      } else if (this.interface == 3) {
        this.$refs['ruleForm'].validate(async val => {
          if (!val) {
            return
          }
          if (this.activityId) {
            var { data: res } = await this.$http.post(
              '/admin/Activity/ticketAdd',
              {
                ...this.ruleForm,
                activityId: this.activityId
              }
            )
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$emit('getTicket')
              this.drawer = false
            } else {
              this.$message.error(res.message)
            }
            return
          }
          this.$emit('getlist', {...this.ruleForm})
          // this.ruleForm=this.$data.ruleForm
          this.ruleForm = this.$options.data.call(this).ruleForm
          // return
          this.drawer = false
        })
        return
      }

      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.drawer = false
        this.$refs.multipleTable.clearSelection()
        this.$emit('getlist')
      } else {
        this.$message.error(res.message)
      }
    },
    selChange(val) {
      //   console.log(val)
      if (this.interface == 1) {
        this.selectTheUser = val.map(item => item.uid)
      } else {
        this.selectTheUser = val.map(item => item.id)
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getlist()
    },
    async getlist() {
      if (this.interface == 1) {
        var { data: res } = await this.$http.get('/admin/AuthMember/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize
          }
        })
      } else if (this.interface == 2) {
        var { data: res } = await this.$http.get(
          '/admin/Activity/managerList',
          {
            params: {
              page: this.currentPage,
              pageSize: this.pagesize
            }
          }
        )
      }

      if (res.errorCode == 200) {
        this.list = res.data.list
        this.total = res.data.totalCount
      } else {
        this.$message.error(res.message)
        //
      }
    },
    handleClose() {
      if (this.interface == 4) {
        this.drawer = false

        return
      }
      this.ruleForm = this.$options.data.call(this).ruleForm
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection()
      }
      this.drawer = false
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
/deep/.el-drawer__body {
  overflow: auto;
}
</style>
